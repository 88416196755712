import { IDashboard } from "~/dto/IDashboard";
import { plantHarvestApiService } from "./@common";
import { PageRequest, PageResponse } from "~/@ui/GridControl/models";

const ENDPOINT = {

  POST_NEWS: "/api/admin/post-news".trim(),

  DASHBOARD: "/api/admin/dashboard".trim(),

  CREATE_PARTNER: "/api/admin/create-partner".trim(),
  LIST_PARTNER: "/api/admin/list-partner".trim(),

}

export interface IPartner {
  id: string
  createdDate: string
  updatedDate: string
  createdBy: any
  updatedBy: any
  version: number
  code: string
  name: string
  desc: string
  linkRef: string
}


export class PLantHarvestService {

  async postNews(body: { description: string, imageTitle: string }) {
    return plantHarvestApiService.post(ENDPOINT.POST_NEWS, body);
  }

  async dashboard(body: {}) {
    return plantHarvestApiService.get<IDashboard>(ENDPOINT.DASHBOARD, body);
  }

  async createPartner(body: { name: string, desc: string }) {
    return plantHarvestApiService.post<IPartner>(ENDPOINT.CREATE_PARTNER, body);
  }

  async listPartner(params: PageRequest) {
    return plantHarvestApiService.get<PageResponse<IPartner>>(ENDPOINT.LIST_PARTNER, params);
  }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PLantHarvestService();
