
import { useState, FC, useCallback, useRef } from 'react';
import { Button, Divider, theme, Col, Row, Modal, Tooltip, Tag, Image, Avatar, Spin, Input, DatePicker, Descriptions, Typography, } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { PlusCircleFilled, SaveOutlined, ScanOutlined, SearchOutlined, SendOutlined, UploadOutlined, } from '@ant-design/icons';
import moment from 'moment';
import BaseView from '~/components/BaseView';
import { createSearchParams, useNavigate } from 'react-router-dom';
import helper from '~/common/helper/helper';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { optionalApiService, toastService, uploadApiService } from '~/services';
import plantHarvestService, { IPartner } from '~/services/plant.harvest.service';
import DefUpload from '~/components/DefUpload';
import TableControl from "~/@ui/TableControl";
import { PageResponse } from "~/@ui/GridControl/models";

const { Title } = Typography;

type IProps = {
}

export const PartnerView: FC<IProps> = (props: IProps) => {

  const { token: { colorPrimary } } = theme.useToken();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [name, setName] = useState("");


  const create = async () => {
    try {
      const res = await plantHarvestService.createPartner({ name, desc: name });
      setRefresh(!refresh);
      toastService.success("Create Partner success")
    } catch (error) {
      toastService.handleError(error)
    }

  }

  const columns: ColumnsType<IPartner> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Link Ref",
      dataIndex: "linkRef",
      key: "linkRef",
      render: (value: any, record: IPartner, index: number) => {
        return <Tag
          color={"purple"}
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open(record.linkRef)
          }}
        >
          <span>Link Ref</span>
        </Tag>
      }
    }

  ];



  const loadData = useCallback(async ({ pageIndex, pageSize }: { pageIndex: number, pageSize: number }) => {
    const rs = await plantHarvestService.listPartner({ pageIndex, pageSize });
    setRefresh(false);
    return {
      data: rs.data,
      total: rs.total
    } as PageResponse;
  }, [refresh])



  return (
    <BaseView>

      <Spin
        spinning={false}
      >

        <Row
          gutter={[24, 2]}
          style={{ paddingBottom: "50px", alignItems: "end" }}
        >

          <Col span={6}>
            <Title level={5}>
              Partner name
            </Title>
            <Input
              placeholder='Partner name'
              value={name}
              onChange={e => { setName(e.target.value) }}
            />
          </Col>
          <Col span={6}>
            <Button
              style={{ width: "180px" }}
              type="primary"
              loading={isLoading}
              onClick={create}
            >
              Create Partner
              <PlusCircleFilled />
            </Button>
          </Col>
          <TableControl
            columns={columns}
            defaultPageSize={10}
            loadData={loadData}
          />

        </Row>

      </Spin>

    </BaseView>
  )
}
